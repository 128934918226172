.container {
    position: absolute;
    height: 300px;
    width: 600px;
    top: 60px;
    left: calc(50% - 300px);
    display: flex;
}

.card {
    display: flex;
    height: 100px;
    width: 200px;
    background-color: #17141d;
    border-radius: 10px;
    box-shadow: -2px 0 10px #000;
    /*   margin-left: -50px; */
    transition: 0.4s ease-out;
    position: relative;
    left: 0px;
}

.card:not(:first-child) {
    margin-left: -180px;
}